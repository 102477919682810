export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24')
];

export const server_loads = [0];

export const dictionary = {
		"/": [2],
		"/about-us": [3],
		"/about-us/careers": [4],
		"/about-us/our-locations": [5],
		"/about-us/our-story": [6],
		"/about-us/our-team": [7],
		"/about-us/press": [8],
		"/about-us/press/[slug]": [9],
		"/capabilities": [10],
		"/capabilities/partner-with-us": [11],
		"/contact-us": [12],
		"/kitchen-sink": [13],
		"/news": [14],
		"/news/[slug]": [15],
		"/privacy-policy": [16],
		"/products": [17],
		"/products/category": [18],
		"/products/category/landing": [19],
		"/products/category/landing/detail": [20],
		"/terms-and-conditions": [21],
		"/who-we-serve": [22],
		"/who-we-serve/category": [23],
		"/who-we-serve/category/detail": [24]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';